import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import Helmet from 'react-helmet'

import { Layout, Section, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import {
    motion,
	transform,
} from 'framer-motion'

const Timetable = (props) => {
	const { x, y } = useWindowScroll();
	const { width, height } = useWindowSize()
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

	// Intro

	const renderIntro = () => {
		return (
			<Intro>
				<Title>Timetable</Title>
				<Text dangerouslySetInnerHTML={{ __html: data.introduction }} />
			</Intro>
		)
	}


	return (
		<Layout
             meta={data && data.seo}
        >
			<Container>
				{renderIntro()}
				<MindBody dangerouslySetInnerHTML={{ __html: data.mindbody_embed }} />		
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.h1``
const Title = styled.h1``
const Text = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	Margin-top: 200px;
	align-items: center;
    justify-content: center;
`

// Intro

const Intro = styled.div`
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;

	${media.tablet`
		margin-bottom: 20px;	
	`}

	${Title} {
		${type.heading1};
		padding-bottom: 32px;

		${media.tablet`
			padding-bottom: 20px;	
		`}
	}

	${Text} {
		${type.body};
		max-width: 80%;

		${media.tablet`
			max-width: 100%;
		`}
	}
`

// MindBody

const MindBody = styled.div`
	min-height: 780px;
	max-width: 825px;
	margin-bottom: 60px;
	width: 100%;
	height: 100%;

	${media.tablet`
		margin-bottom: 20px;
		min-height: 534px;	
	`}
`

// Data

export const query = graphql`
     query {
         allWordpressInfopages(filter: { slug: { eq: "timetable" } }) {
             nodes {
                 acf_json
             }
         }
     }
`

export default Timetable